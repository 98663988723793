import React from 'react';
import 'twin.macro';
import UserMenuContainer from '@leuven2030/framework/User/UserMenuContainer';
import UserMenuDetails from '@leuven2030/school/User/UserMenuDetails';
import UserMenuList from '@leuven2030/school/User/UserMenuList';
import UserMenuButton from '@leuven2030/school/User/UserMenuButton';
import ServerEnv from '@leuven2030/framework/Server/ServerEnv';

const UserMenu = () => {
  if (!ServerEnv.isDev) {
    return <></>;
  }
  return (
    <UserMenuContainer UserMenuButton={UserMenuButton}>
      <div tw="w-auto sm:w-64">
        <UserMenuDetails />
        <UserMenuList />
      </div>
    </UserMenuContainer>
  );
};

export default UserMenu;
