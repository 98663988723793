import BasePageHeader from '@leuven2030/framework/Layout/LayoutHeader';
import NavigationMenu from '@leuven2030/framework/Navigation/NavigationMenu';
import Link from 'next/link';
import get from 'lodash/get';
import PageHeaderLogo from './PageHeaderLogo.svg';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';
import SearchMenu from '@leuven2030/framework/Search/SearchMenu';
import UserMenu from '@leuven2030/school/User/UserMenu';

const PageHeader = () => {
  const {
    prismic: { search_config, layout }
  } = useContext(PageContext);
  const header_menu = get(layout, 'header_menu') || [];

  return (
    <BasePageHeader>
      <div tw=" flex flex-col items-start sm:(flex-row items-center space-x-6 pb-0 h-auto justify-between) relative ">
        <div tw="flex items-center space-x-3 justify-between sm:(justify-start w-auto) w-full ">
          <Link href="/">
            <a tw="cursor-pointer opacity-100 hover:opacity-75 h-16 py-3 ">
              <PageHeaderLogo tw="w-auto h-full" />
            </a>
          </Link>
          {search_config && <SearchMenu />}
        </div>
        <div tw="flex items-center pb-3 space-x-3">
          {header_menu.map(({ item }) => {
            return <NavigationMenu key={item.title} data={item} />;
          })}
          <UserMenu />
        </div>
      </div>
    </BasePageHeader>
  );
};

export default PageHeader;
