import 'twin.macro';
import SubscribeButton from '@leuven2030/framework/Subscription/SubscribeButton';
import LayoutFooterMenu from '@leuven2030/framework/Layout/LayoutFooterMenu';
import React from 'react';

const LayoutFooter = () => {
  return (
    <footer>
      <div tw="container mx-auto  px-3">
        <div tw="lg:(grid grid-cols-3 gap-8) py-12">
          <LayoutFooterMenu />
          <div tw="mt-8 lg:mt-0 flex sm:justify-end items-start">
            <SubscribeButton />
          </div>
        </div>
        <div tw=" py-3  md:flex md:items-center md:justify-between">
          <p tw="text-base leading-6 text-gray-300 md:mt-0 md:order-1 opacity-50">
            &copy; {new Date().getFullYear()} Leuven Klimaatneutraal 2030 vzw.
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default LayoutFooter;
