import 'twin.macro';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import React, { FC } from 'react';
import { NavigationMenuLinkGetProps } from '@leuven2030/framework/Navigation/NavigationMenuLink';

type Props = {
  data: any;
};
const NavigationFooterItem: FC<Props> = ({ data, ...props }) => {
  const { meta, icon, label, href } = NavigationMenuLinkGetProps(data, {
    iconOnly: true
  });
  return (
    <div tw="flow-root">
      <NavigationLink
        url={href}
        tw="-m-3 p-3 space-x-2 flex items-center rounded-sm text-base leading-6 font-medium text-gray-900 hover:(bg-gray-100 bg-opacity-25) transition ease-in-out duration-150"
        prismicMeta={meta}
        {...props}
      >
        {icon}
        <span tw="whitespace-nowrap  text-sm">{label}</span>
      </NavigationLink>
    </div>
  );
};

export default NavigationFooterItem;
