import Image from '@leuven2030/framework/Image/Image';
import { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
const IdeationLinks = () => {
  const {
    prismic: {
      ideation_modal: { introduction, profiles }
    }
  } = useContext(PageContext);
  return (
    <div tw="w-screen max-w-full bg-primary-600 pb-16 sm:pb-0">
      <div tw="py-12 bg-white">
        <PrismicText tw="text-center prose" text={introduction} />
      </div>
      <div tw="flex flex-col space-y-6 sm:(flex-row items-stretch space-x-6 space-y-0)  justify-center   p-6">
        {profiles.map(
          ({
            profile: {
              title,
              link: { url },
              cover: { url: coverUrl }
            }
          }) => {
            return (
              <a href={url} target="_blank" tw="flex-1">
                <div tw="overflow-hidden rounded-sm text-center bg-white  shadow-lg cursor-pointer ">
                  <div tw="relative h-32 w-full ">
                    <Image
                      tw="absolute inset-0 w-full h-full object-cover"
                      src={coverUrl}
                      width={300}
                      height={260}
                    />
                  </div>
                  <h2 tw="text-lg text-primary-600 p-6 whitespace-pre-line font-bold uppercase">
                    {title.split(' ').join('\n')}
                  </h2>
                </div>
              </a>
            );
          }
        )}
      </div>
    </div>
  );
};

export default IdeationLinks;
