import React from 'react';
import PageHeader from '@leuven2030/school/Page/PageHeader';
import LayoutFooter from '@leuven2030/framework/Layout/LayoutFooter';
import 'twin.macro';
import PageAnnouncement from '@leuven2030/framework/Page/PageAnnouncement';
import { useRouter } from 'next/router';
import { NavigationLinkModals } from '@leuven2030/framework/Navigation/NavigationLink';
import SubscriptionForm from '@leuven2030/framework/Subscription/SubscriptionForm';
import NavigationProvider from '@leuven2030/framework/Navigation/NavigationProvider';
import IdeationLinks from '@leuven2030/school/Ideation/IdeationLinks';
import ClimatePlanProvider from '@leuven2030/framework/ClimatePlan/ClimatePlanProvider';
import GroupProvider from '@leuven2030/framework/Group/GroupProvider';

const modals: NavigationLinkModals = [
  {
    uid: 'subscribe',
    Component: SubscriptionForm
  },
  {
    uid: 'bpart-links',
    Component: IdeationLinks
  }
];

const PageLayout = ({ children, ...props }) => {
  const router = useRouter();

  return (
    <NavigationProvider modals={modals}>
      <GroupProvider>
        <ClimatePlanProvider>
          <div tw="bg-primary-700">
            <PageAnnouncement />
            <PageHeader />
            <div tw="mx-auto min-h-screen">
              <div tw="mx-auto w-full pb-6">
                <div tw="container -mt-10 z-20 relative px-0 sm:(px-3) max-w-full">
                  <div tw="shadow-2xl">
                    <div
                      tw="flex align-top bg-white rounded-sm overflow-hidden min-h-screen relative"
                      {...props}
                    >
                      {!router.isFallback && children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LayoutFooter />
          </div>
        </ClimatePlanProvider>
      </GroupProvider>
    </NavigationProvider>
  );
};
export default PageLayout;
