import React, { createContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import some from 'lodash/some';
const schoolListQuery = gql`
  {
    group {
      canEdit {
        id
        title
        cover
        formatted_address
        climatePlan {
          actions {
            actionId
          }
        }
      }
    }
  }
`;

export const GroupContext = createContext({
  canEdit: [],
  getActionStatus: (value) => null,
  getActionStatusByGroup: (value) => ({
    groups: [],
    countAdded: 0,
    canAdd: false,
    allAdded: false
  })
});

const GroupProvider = ({ children }) => {
  const { data } = useQuery(schoolListQuery);
  const canEdit = data ? data.group.canEdit : [];

  function getActionStatusByGroup({ actionId }) {
    let countAdded = 0;
    const groups = canEdit.map((group) => {
      const result = getActionStatus({ group, actionId });
      if (result.isAdded) {
        countAdded++;
      }
      return result;
    });
    return {
      groups,
      countAdded,
      allAdded: countAdded == groups.length,
      canAdd: countAdded < groups.length
    };
  }

  function getActionStatus({ group, actionId }) {
    const {
      id,
      climatePlan: { actions }
    } = group;
    const isAdded = some(actions, ['actionId', actionId]);

    return {
      id,
      isAdded
    };
  }

  return (
    <GroupContext.Provider
      value={{ canEdit, getActionStatusByGroup, getActionStatus }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export default GroupProvider;
