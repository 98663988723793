import React, { FC } from 'react';
import 'twin.macro';
import NavigationMenuLink, {
  NavigationMenuLinkGetProps
} from '@leuven2030/framework/Navigation/NavigationMenuLink';
const NavigationMenuItem: FC<any> = ({ data, ...props }) => {
  const navItemProps = NavigationMenuLinkGetProps(data);
  const {
    page: { items }
  } = data;
  if (items) {
    return (
      <div>
        <h3 tw="mb-3">{navItemProps.label}</h3>
        <div tw="ml-3">
          {items.map((item, index) => (
            <NavigationMenuItem key={index} data={item} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <NavigationMenuLink tw="w-full max-w-xs" {...props} {...navItemProps} />
  );
};

export default NavigationMenuItem;
