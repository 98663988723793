import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';
const LayoutFooterMenu = () => {
  const {
    prismic: { layout }
  } = useContext(PageContext);
  if (!layout) {
    return <></>;
  }
  const { footer_menu = [] } = layout;

  return (
    <div tw="grid grid-cols-2 gap-8 lg:(col-span-2 grid-cols-3) ">
      {footer_menu.map(({ item: { items, title } }, index) => {
        return (
          <div key={title || index} tw="w-full">
            <h4 tw="text-sm leading-5 font-semibold text-gray-100 tracking-wider uppercase">
              {title}
            </h4>
            <ul tw="mt-4 space-y-4">
              {items.map(({ page: { title, _meta, url }, label }, index) => {
                return (
                  <li key={title || label || index}>
                    <NavigationLink
                      url={url}
                      prismicMeta={_meta}
                      tw="text-sm text-gray-300 hover:text-white cursor-pointer"
                    >
                      {label || title}
                    </NavigationLink>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default LayoutFooterMenu;
