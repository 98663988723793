import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React, { useContext } from 'react';
import 'twin.macro';
import { SearchContext } from './SearchProvider';
import { connectRefinementList } from 'react-instantsearch-dom';
const RefinementList = ({
  items: algoliaItems,
  refine,
  createURL,
  currentRefinement,
  canRefine,
  isFromSearch,
  searchForItems,
  showMoreLimit,
  showMore,
  indexContextValue,
  ...props
}) => {
  const { setSelectedGroup, searchGroups, selectedGroup } = useContext(
    SearchContext
  );
  function handleChange(e, newValue) {
    const algoliaItem = algoliaItems.find(({ label }) => label == newValue.uid);
    if (algoliaItem) {
      console.log(algoliaItem);
      refine([algoliaItem.label]);
    }
    //console.log('algoliaItem', algoliaItem);
    setSelectedGroup(newValue);
  }
  if (!searchGroups.length) {
    return <></>;
  }
  return (
    <div {...props}>
      <Tabs
        scrollButtons="auto"
        value={selectedGroup}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {searchGroups.map((searchGroup) => {
          const { title, uid } = searchGroup;

          return (
            <Tab
              value={searchGroup}
              key={uid}
              label={<span tw="lowercase">{title}</span>}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

const SearchGroups = connectRefinementList(RefinementList);

export default SearchGroups;
