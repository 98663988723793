import 'twin.macro';
import NavigationFooterItem from '@leuven2030/framework/Navigation/NavigationFooterItem';
import React from 'react';
import filter from 'lodash/filter';

const NavigationMenuFooter = ({ items }) => {
  const filteredItems = filter(items, (item) => {
    if (!item) {
      return;
    }
    if (!item.page) {
      return;
    }
    return true;
  });

  if (!filteredItems.length) {
    return <></>;
  }
  return (
    <div tw="flex flex-col space-y-6 px-4 py-5 sm:(flex-row space-y-0 space-x-10 px-6 items-center) lg:px-8 bg-primary-500">
      {filteredItems.map((data, index) => {
        return <NavigationFooterItem data={data} key={index} tw="text-white" />;
      })}
    </div>
  );
};

export default NavigationMenuFooter;
