import NavigationMenuItem from '@leuven2030/framework/Navigation/NavigationMenuItem';
import NavigationMenuButton from '@leuven2030/framework/Navigation/NavigationMenuButton';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Menu from '@leuven2030/framework/Menu/Menu';
import PageLink from '@leuven2030/framework/Page/PageLink';
import NavigationMenuFooter from '@leuven2030/framework/Navigation/NavigationMenuFooter';
import tw from 'twin.macro';

const NavigationMenu = ({ data, buttonProps = {} }) => {
  const {
    prismic: {
      layout: { read_more }
    }
  } = useContext(PageContext);
  const {
    title,
    items,
    disabled,
    footer,
    page,
    _meta: { uid }
  } = data;

  if (disabled) {
    return <></>;
  }
  return (
    <Menu
      triggerComponent={<NavigationMenuButton label={title} {...buttonProps} />}
      mobileConfig={{ mobileContainerProps: { css: tw`bg-primary-500` } }}
    >
      <div tw="relative mx-auto">
        <div tw=" p-3 sm:(p-6) space-y-6 bg-white">
          <div tw="flex items-center justify-between">
            <h3 tw="text-sm leading-5 font-medium tracking-wide text-gray-500 uppercase">
              {title}
            </h3>
            <div tw="flex items-center">
              {page && <PageLink title={read_more} _meta={page._meta} />}
            </div>
          </div>
          <div tw=" grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
            {items.map((item, index) => {
              if (!item.page) {
                return;
              }
              return <NavigationMenuItem key={index} data={item} />;
            })}
          </div>
        </div>

        <NavigationMenuFooter items={footer} />
      </div>
    </Menu>
  );
};

export default NavigationMenu;
