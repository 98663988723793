import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Button from '@leuven2030/framework/Button/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import 'twin.macro';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
const PageAnnouncement = () => {
  const {
    prismic: { layout }
  } = useContext(PageContext);
  if (!layout) {
    return <></>;
  }
  const { announcement_text, announcement_link } = layout;

  return (
    <div tw="bg-gray-800 py-2 max-w-full overflow-hidden">
      <div tw="text-white text-xs container px-3 flex items-center justify-between flex-col space-y-2 text-center sm:(flex-row space-y-0! text-left)">
        <span>{announcement_text}</span>
        <NavigationLink prismicMeta={announcement_link}>
          <Button
            variant="outlined"
            size="small"
            tw="border-gray-300 text-gray-200 flex-shrink-0 text-xs"
            color="inherit"
            endIcon={<NavigateNextIcon tw="text-white" />}
          >
            {announcement_link.title}
          </Button>
        </NavigationLink>
      </div>
    </div>
  );
};

export default PageAnnouncement;
