import * as Types from '../src/Graphql/GraphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  join__FieldSet: any;
};






export type ClimatePlan = {
  __typename?: 'ClimatePlan';
  id: Scalars['String'];
  groupId: Scalars['String'];
  actions?: Maybe<Array<ClimatePlanAction>>;
};

export type ClimatePlanAction = {
  __typename?: 'ClimatePlanAction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  storeType: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  groupId: Scalars['String'];
  actionId: Scalars['String'];
  document: PlanningActionDocument;
};

export type ClimatePlanMutation = {
  __typename?: 'ClimatePlanMutation';
  addAction: Scalars['String'];
};


export type ClimatePlanMutationAddActionArgs = {
  input: ClimatePlanMutationAddActionInput;
};

export type ClimatePlanMutationAddActionInput = {
  groupId: Scalars['String'];
  actionId: Scalars['String'];
};

export type ClimatePlanQuery = {
  __typename?: 'ClimatePlanQuery';
  get: ClimatePlan;
};


export type ClimatePlanQueryGetArgs = {
  id: Scalars['String'];
};

export type ContentQuery = {
  __typename?: 'ContentQuery';
  layout: Layout;
};


export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  storeType: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  type: GroupType;
  photos?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Scalars['Float']>>;
  formatted_address?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  climatePlan: ClimatePlan;
};

export type GroupMemberQuery = {
  __typename?: 'GroupMemberQuery';
  id: Scalars['String'];
};

export type GroupQuery = {
  __typename?: 'GroupQuery';
  id: Scalars['String'];
  canView: Array<Group>;
  member: GroupMemberQuery;
  get: Group;
  canEdit: Array<Group>;
};


export type GroupQueryGetArgs = {
  id: Scalars['String'];
  type: GroupType;
};

export enum GroupType {
  ClimateProject = 'CLIMATE_PROJECT',
  School = 'SCHOOL'
}

export type InvitationMutation = {
  __typename?: 'InvitationMutation';
  add: Scalars['String'];
};


export type InvitationMutationAddArgs = {
  input: InvitationMutationAddInput;
};

export type InvitationMutationAddInput = {
  groupId: Scalars['String'];
};


export enum Join__Graph {
  ClimatePlan = 'CLIMATE_PLAN',
  Content = 'CONTENT',
  Group = 'GROUP',
  Invitation = 'INVITATION',
  Subscription = 'SUBSCRIPTION'
}

export type Layout = {
  __typename?: 'Layout';
  website_title: Scalars['String'];
  read_more: Scalars['String'];
  announcement_text: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  climatePlan: ClimatePlanMutation;
  invitation: InvitationMutation;
  subscription: SubscriptionMutation;
};

export type PlanningActionDocument = {
  __typename?: 'PlanningActionDocument';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  climatePlan: ClimatePlanQuery;
  content: ContentQuery;
  group: GroupQuery;
  subscription: SubscriptionQuery;
};

export type SubscriptionMutation = {
  __typename?: 'SubscriptionMutation';
  add: Scalars['String'];
};


export type SubscriptionMutationAddArgs = {
  input: SubscriptionMutationAddInput;
};

export type SubscriptionMutationAddInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  list: Array<Scalars['String']>;
  recaptcha: Scalars['String'];
};

export type SubscriptionQuery = {
  __typename?: 'SubscriptionQuery';
  id: Scalars['String'];
};

export type PlanningAddActionMutationVariables = Types.Exact<{
  input: Types.ClimatePlanMutationAddActionInput;
}>;


export type PlanningAddActionMutation = (
  { __typename?: 'Mutation' }
  & { climatePlan: (
    { __typename?: 'ClimatePlanMutation' }
    & Pick<Types.ClimatePlanMutation, 'addAction'>
  ) }
);


export const PlanningAddActionDocument = gql`
    mutation PlanningAddAction($input: ClimatePlanMutationAddActionInput!) {
  climatePlan {
    addAction(input: $input)
  }
}
    `;
export type PlanningAddActionMutationFn = Apollo.MutationFunction<PlanningAddActionMutation, PlanningAddActionMutationVariables>;

/**
 * __usePlanningAddActionMutation__
 *
 * To run a mutation, you first call `usePlanningAddActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanningAddActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planningAddActionMutation, { data, loading, error }] = usePlanningAddActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlanningAddActionMutation(baseOptions?: Apollo.MutationHookOptions<PlanningAddActionMutation, PlanningAddActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanningAddActionMutation, PlanningAddActionMutationVariables>(PlanningAddActionDocument, options);
      }
export type PlanningAddActionMutationHookResult = ReturnType<typeof usePlanningAddActionMutation>;
export type PlanningAddActionMutationResult = Apollo.MutationResult<PlanningAddActionMutation>;
export type PlanningAddActionMutationOptions = Apollo.BaseMutationOptions<PlanningAddActionMutation, PlanningAddActionMutationVariables>;