import React, { createContext, useState } from 'react';
import { gql } from '@apollo/client';
import ClimatePlanAddPopup from '@leuven2030/framework/ClimatePlan/ClimatePlanAddPopup';
import { usePlanningAddActionMutation } from '@leuven2030/framework/ClimatePlan/PlanningAddAction.generated';

type ClimatePlanActionPopupContextProps = {
  actionId: string;
};
type ClimatePlanActionAddOptions = { groupId: string; actionId: string };
type ClimatePlanContextProps = {
  actions: any[];
  addAction: (options: ClimatePlanActionAddOptions) => void;
  addActionPopupOpen?: ClimatePlanActionPopupContextProps;
  setAddActionPopupOpen: (value: ClimatePlanActionPopupContextProps) => void;
};
export const ClimatePlanContext = createContext<ClimatePlanContextProps>({
  actions: [],
  addAction: () => null,
  setAddActionPopupOpen: () => null
});

const ClimatePlanProvider = ({ children }) => {
  // const { data } = useQuery(ClimatePlanQuery, {
  //   variables: {
  //     groupId: '019caf20a7739e9b6504291cbe8d8a75e44fe97d'
  //   }
  // });
  const [addActionMutation] = usePlanningAddActionMutation();

  const [addActionPopupOpen, setAddActionPopupOpen] = useState(null);

  async function addAction({ groupId, actionId }: ClimatePlanActionAddOptions) {
    await addActionMutation({
      variables: {
        input: {
          groupId,
          actionId
        }
      }
    });
    console.log('success');
  }
  return (
    <ClimatePlanContext.Provider
      value={{
        addActionPopupOpen,
        addAction,
        setAddActionPopupOpen,
        actions: []
      }}
    >
      <ClimatePlanAddPopup />
      {children}
    </ClimatePlanContext.Provider>
  );
};

export default ClimatePlanProvider;
