import ModalDialog from '@leuven2030/framework/Modal/ModalDialog';
import React, { useContext } from 'react';
import { ClimatePlanContext } from '@leuven2030/framework/ClimatePlan/ClimatePlanProvider';
import { GroupContext } from '@leuven2030/framework/Group/GroupProvider';
import GroupListItem from '@leuven2030/framework/Group/GroupListItem';
import 'twin.macro';
const ClimatePlanAddPopup = () => {
  const { addActionPopupOpen, setAddActionPopupOpen, addAction } = useContext(
    ClimatePlanContext
  );
  const { canEdit, getActionStatus } = useContext(GroupContext);

  return (
    <ModalDialog
      tw="w-screen max-w-sm"
      open={addActionPopupOpen}
      onClose={() => {
        setAddActionPopupOpen(null);
      }}
    >
      {addActionPopupOpen && (
        <div tw="space-y-6">
          <h2 tw="text-xl text-gray-600 px-6">For which school?</h2>
          <div tw="bg-gray-100">
            {canEdit.map((group) => {
              const actionId = addActionPopupOpen.actionId;
              const status = getActionStatus({ group, actionId });
              return (
                <GroupListItem
                  selected={status.isAdded}
                  key={group.id}
                  data={group}
                  onClick={() => {
                    addAction({
                      groupId: group.id,
                      actionId: actionId
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </ModalDialog>
  );
};

export default ClimatePlanAddPopup;
