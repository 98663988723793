import React, { FC } from 'react';
import SchoolIcon from '@material-ui/icons/School';
import ListItemMaterial from '@material-ui/core/ListItem';
import { css } from '@emotion/css';
import tw from 'twin.macro';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Image from '@leuven2030/framework/Image/Image';

type Props = {
  data: any;
  Icon?: FC;
  onClick?: () => void;
};
const GroupListItem: FC<Props> = ({ data, selected, onClick, Icon }) => {
  const { title, formatted_address, cover } = data;
  //const { selected } = useContext(AutocompleteContext);

  return (
    <ListItemMaterial
      ContainerComponent="div"
      ContainerProps={{
        className: css`
          ${tw`w-full`}
        `
      }}
      tw="py-3 w-full"
      button={true}
      onClick={onClick}
    >
      {cover && (
        <ListItemAvatar tw="mr-4">
          <Image tw="w-16 h-16" src={cover} width={80} height={80} />
        </ListItemAvatar>
      )}
      {!cover && (
        <ListItemAvatar tw="mr-4">
          <div tw="w-16 h-16 flex items-center justify-center bg-gray-200 rounded-sm ">
            <SchoolIcon tw="w-8 h-8 text-gray-600" />
          </div>
        </ListItemAvatar>
      )}
      <ListItemText tw="pr-4" primary={title} secondary={formatted_address} />
      {selected && <div>ss</div>}
    </ListItemMaterial>
  );
};

export default GroupListItem;
