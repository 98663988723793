import { theme } from 'twin.macro';
import { FC } from 'react';
const LayoutHeader: FC<{
  minHeight?: number;
}> = ({ minHeight = 150, children, ...props }) => {
  return (
    <div
      id="page-header"
      tw="bg-gray-100 relative z-10 flex items-center pb-8"
      css={[
        {
          minHeight,
          '&:after': [
            {
              position: 'absolute',
              left: 0,
              content: '""',
              borderRight: '100vw solid transparent',
              maxWidth: '100%',
              overflow: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
              top: '100%',
              borderTop: `40px solid ${theme`colors.gray.100`}`
            }
          ]
        }
      ]}
      {...props}
    >
      <div tw="container p-3 mx-auto">{children}</div>
    </div>
  );
};

export default LayoutHeader;
