import React, { FC, useContext } from 'react';
import UserContext from '@leuven2030/framework/User/UserContext';
import 'twin.macro';
import ContactAvatar from '@leuven2030/framework/Contact/ContactAvatar';
const UserAvatar: FC = (props) => {
  const { user: { email } = { email: null } } = useContext(UserContext);

  return <ContactAvatar tw="bg-white text-gray-500" email={email} {...props} />;
};

export default UserAvatar;
