import NewsSubscribe from '@leuven2030/framework/News/NewsSubscribe';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';

const SubscribeButton = () => {
  const {
    prismic: { news_settings }
  } = useContext(PageContext);

  if (!news_settings) {
    return <></>;
  }

  const { subscribe_title } = news_settings;

  return (
    <div tw="space-y-3 sm:max-w-xs">
      <h4 tw="text-sm leading-5 font-semibold text-white tracking-wider uppercase">
        {subscribe_title}
      </h4>
      <NewsSubscribe orientation="vertical" />
    </div>
  );
};

export default SubscribeButton;
